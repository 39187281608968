<template>
  <v-container >
    <backArrow style="margin-left: 12px; margin-top: 5px; position: initial;" :route="'minha-empresa'"/>

    <v-row class="px-3 py-3" style="height: 93vh;">
      <v-col cols="12" align-self="start">
        <v-row>
          <v-col cols="12">
            <p class="main-title mb-0 pb-0">Telemedicina</p>
            <p class="mt-2" style="color: var(--v-primary)">Selecione o dependente</p>
          </v-col>
        </v-row>

        <v-card class="mb-7" rounded="lg" v-for="(dependent, index) in dependents" :key="index">
          <v-row>
            <v-col cols="9" @click="onClickDependetCard(dependent, index)">
              <p class="pl-4 mb-0 main-text text-truncate" style="color: var(--v-primary)">
                {{ dependent && dependent.physicalPerson && dependent.physicalPerson.name ? dependent.physicalPerson.name : '-'}}
              </p>
              <p class="pl-4 mb-0 font-weight-bold status-text">
                Dependente {{ formatDigit(index) }}
              </p>
            </v-col>
            <v-col cols="3">
              <v-switch class="py-0 my-0" v-model="dependent.isActive" color="primary" readonly @click="onClickSwitch(dependent, index)"></v-switch>
              <div @click="onClickDependetCard(dependent, index)" style="height: 100%"></div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>

      <v-col cols="12" class="px-4" align-self="end" v-if="verifyIfExistsActive">
        <generic-btn style="width: 100%" :btn-props="{ disabled: false }" :on-click="() => onClickFinish()">
          Concluir
        </generic-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import backArrow from '../../components/covid/back-arrow';
import { mapMutations } from "vuex";

export default {
  name: "DependentsManagement",
  components: {
    backArrow
  },
  data: () => ({
    dependents: []
  }),
  computed: {
    verifyIfExistsActive() {
      if(this.dependents && this.dependents.length) {
        return this.dependents.some(dependent => dependent.isActive);
      }
      return false;
    }
  },
  methods: {
    ...mapMutations(["showmsg", "loading"]),
    formatDigit(index) {
      if(index < 10) {
        index++;
        return ("0" + index).slice(-2);
      }
      return index;
    },
    onClickSwitch(dependent, indexOfDependent) {
      if(dependent && dependent.isSaved) {
        dependent.isActive = !dependent.isActive;
      } else {
        this.$router.push({ name: 'telemedicineInsertDependent', params: { dependent: dependent, dependents: this.dependents, indexOfDependent: indexOfDependent } });
      }
    },
    onClickDependetCard(dependent, indexOfDependent) {
      this.$router.push({ name: 'telemedicineInsertDependent', params: { dependent: dependent, dependents: this.dependents, indexOfDependent: indexOfDependent } });
    },
    onClickFinish() {
      if(this.dependents.length) {
        this.dependents = this.dependents.filter(dependent => dependent.isActive);
      }
      this.$router.push({ name: 'telemedicineCartManagement', params: { dependents: this.dependents } });
    }
  },
  async mounted() {
    if(this.$route.params && this.$route.params.dependents ) {
      this.dependents = this.$route.params.dependents;

      if(this.dependents.length && !this.$route.params.isSaved) {
        const allDependents = [];
        this.dependents.forEach(dependent => {
          allDependents.push({
            ...dependent,
            isActive: false,
          });
        })
        this.dependents = allDependents;
      }
    } else {
      this.$router.back();
    }
  },
};
</script>

<style>
</style>
